<template lang="pug">
    div
        Details(ref="practiceDetails")
        ExtendedDetails(ref="extendedDetails")
        OrgWebsiteAndEmail(ref="orgWebsiteAndEmailForm")

        .grid.grid-flow-col.grid-cols-2.gap-6.mt-10
            //.col-span-1
            //    h3.mb-4 Practice Logo
            //    div.mb-4
            //        span.text-sm Your practice logo appears on the top of surveys. If this field is empty, the Quality Practice Accreditation logo will display instead.
            //        span.text-sm.mb-2.ml-1.font-bold File must be PNG or JPG format & minimum height 200px.
            //    v-file-input(
            //        :rules="rules"
            //        accept="image/png, image/jpeg"
            //        filled dense
            //        label="Choose image file"
            //    )
            .col-span-1
                h3.mb-4 Practice Address
                Address(ref="addressForm" v-model="practiceAddress")
</template>

<script>
import Details from '@/components/profile-settings/Details.vue'
import PhoneNumber from '@/components/shared/PhoneNumber.vue'
import Address from '@/components/shared/Address.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { emailValidator, required } from '@/modules/formRules'
import { defaultAddress } from '@/modules/address'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import OrgWebsiteAndEmail from '@/components/profile-settings/OrgWebsiteAndEmail.vue'
import ExtendedDetails from '@/components/profile-settings/ExtendedDetails.vue'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'
import { isValid } from 'date-fns'

const { useGetters } = createNamespacedHelpers('')

const DEFAULT_PHONE = {
    countryCode: 'AU',
    phone: '',
    archived: false
}

export default {
    name: 'PracticeDetails',
    components: { OrgWebsiteAndEmail, Details, PhoneNumber, Address, ExtendedDetails },

    setup(_, { emit }) {
        // const detailsForm = ref({})
        const rules = ref([])
        const practiceDetails = ref({})
        const orgWebsiteAndEmailForm = ref({})
        // const isValid = ref(true)
        const addressForm = ref({})
        const extendedDetails = ref({})
        const emailRules = ref([required, emailValidator])
        const { tenantId } = useGetters(['tenantId'])
        const tenantDetailsStore = useTenantDetailsStore()
        const { updateTenant } = tenantDetailsStore
        const { organization, tenantForm } = storeToRefs(tenantDetailsStore)

        const practiceAddress = computed({
            get: () => {
                return organization.value?.addresses?.at(0) || {...defaultAddress, tenantId: tenantId.value}
            },
            set: (address) => {
                const profileData = {...tenantForm.value}
                if (profileData?.organization?.addresses?.at(0)) profileData.organization.addresses[0] = {...address}
                else profileData.organization.addresses = [{...address}]
                updateTenant({ ...profileData })
                emit('input', { ...profileData })
            }
        })

        const validate = () => {
            const isExtendedDetailsValid = extendedDetails.value?.validate?.()
            const practiceDetailsIsValid = practiceDetails.value?.validate()
            let orgWebsiteAndEmailFormIsValid = orgWebsiteAndEmailForm.value?.validate?.()
            if (orgWebsiteAndEmailFormIsValid === undefined) orgWebsiteAndEmailFormIsValid = true
            isValid.value = isExtendedDetailsValid && practiceDetailsIsValid && orgWebsiteAndEmailFormIsValid
            return isValid.value
        }

        return {
            rules,
            emailRules,
            addressForm,
            practiceAddress,
            practiceDetails,
            orgWebsiteAndEmailForm,
            extendedDetails,
            validate
        }
    }
}
</script>
