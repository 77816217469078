<template lang="pug">
    .py-10.px-8
        .max-w-960.m-auto.mt-6.px-8
            v-form(ref="practiceForm" v-model="isValid")
                h1(class="mt-2 mb-8 text-3xl text-center") Practice Details

                PracticeDetails.w-full(
                    ref="practiceDetailsForm"
                )
                v-alert.mt-8.flex.justify-center.flex-col(
                    v-if="showAlert && !loading"
                    :type="alertType"
                    class="text-sm mx-5 mt-8 mb-0"
                ) {{ alertMessage }}
                .mt-8.flex.justify-center.flex-col
                    v-btn(
                        class="text-white m-auto w-40"
                        depressed
                        :disabled="loading"
                        color="primary"
                        raised
                        large
                        type="button"
                        @click.stop="createOrUpdateTenant"
                    )
                        fa-icon(
                            v-if="loading"
                            icon="spinner"
                            spin
                            class="mr-2 text-white"
                        )
                        | {{ signupBtnText }}
</template>

<script>
import { storeToRefs } from 'pinia'
import PracticeDetails from '@/components/PracticeDetails.vue'
import { useSignup } from '@/composables/useSignup'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { TenantService } from '@/services/tenant.service'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

const tenantService = new TenantService()

export default {
    name: 'PracticeDetailsForm',
    components: { PracticeDetails },
    component: { PracticeDetails },
    props: {
        tenantId: {
            type: String,
            default: ''
        }
    },
    setup(props, {root}) {
        const router = root.$router
        const isValid = ref(true)
        const practiceForm = ref({})
        const practiceDetailsForm = ref({})
        const tenantDetailsStore = useTenantDetailsStore()
        const { updateTenant } = tenantDetailsStore
        const { tenant: profileData } = storeToRefs(tenantDetailsStore)
        const {
            loading,
            showAlert,
            alertMessage,
            alertType,
            tenantId,
            onUserCreated,
        } = useSignup()
        // computed properties
        const signupBtnText = computed(() => {
            return loading.value ? 'Please wait...' : 'Save'
        })
        loading.value = true

        const validate = () => {
            // isValid.value = practiceForm.value.validate() && practiceDetailsForm.value.validate()
            const practiceDetailsFormValid = practiceDetailsForm.value?.validate()
            isValid.value = practiceForm.value.validate() && practiceDetailsFormValid
            return isValid.value
        };

        const createOrUpdateTenant = async () => {
            loading.value = true
            showAlert.value = false
            const isFormValid = validate()
            if (!isFormValid) {
                loading.value = false
                showAlert.value = true
                alertType.value = 'error'
                alertMessage.value = 'Could not save the details, please check the fields above'
                return
            }
            const {organization, typename: tenantTypeName, ...tenantData} = profileData.value
            const {addresses, phones, typename: organizationTypeName, ...organizationData} = organization
            // let's send it to the API
            let type = 'success'
            let message = ''
            try {
                await tenantService.updateTenant(tenantData.tenantId, {
                    ...tenantData,
                    organization: {...organizationData, addresses, phones}
                })

                message = 'Success! Finished updating your details'
            } catch (e) {
                type = 'error'
                message = 'Could not update details, try again later'
            } finally {
                loading.value = false
                alertMessage.value = message
                alertType.value = type
                showAlert.value = true
            }
        }

        onUserCreated(({ data }) => {
            router.push({name: 'ProfileSettings', query: {t: tenantId.value}})
        })

        watch(tenantId, async (newTenantId) => {
            if (!newTenantId) return
            let tenant = null
            if (profileData.value?.tenantId === newTenantId) {
                tenant = { ...profileData.value}
            } else {
                tenant = await tenantService.getTenant(newTenantId)
            } 
            updateTenant({ ...tenant })
        }, { immediate: true })

        onMounted(async () => {
            loading.value = false
        })

        return {
            isValid,
            loading,
            signupBtnText,
            practiceForm,
            practiceDetailsForm,
            showAlert,
            alertMessage,
            alertType,
            createOrUpdateTenant
        }
    }
}
</script>
