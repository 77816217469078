<template lang="pug">
    .detail
        .grid.grid-flow-col.grid-cols-3.gap-4(v-if="organization")
            //.col-span-1
            //    PhoneNumber(ref="phoneNumberForm" v-model="practicePhone")
            .col-span-1
                v-text-field(
                    key="practice-email"
                    label="Practice Email"
                    type="email"
                    v-model.lazy="organization.email"
                    hide-details="auto"
                    filled
                    dense
                )
            .col-span-1
                v-text-field(
                    key="practice-website"
                    label="Practice Website"
                    type="website"
                    v-model.lazy="organization.website"
                    hide-details="auto"
                    filled
                    dense
                )
</template>

<script>
import { computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

export default {
    name: 'OrgWebsiteAndEmail',
    setup() {
        const tenantDetailsStore = useTenantDetailsStore()
        const { organization } = storeToRefs(tenantDetailsStore)

        return { organization }
    }
}
</script>
