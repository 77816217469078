import { useMutation } from '@vue/apollo-composable'
import { INSERT_TENANT } from '@/graphql/mutations/insertTenant.gql'
import { INSERT_USER } from '@/graphql/mutations/insertUser.gql'
import { DELETE_TENANT } from '@/graphql/mutations/deleteTenant.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { computed, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useState } = createNamespacedHelpers('app')

export function useSignup() {
    const { tenantId: stateTenantId } = useState(['tenantId'])
    const showAlert = ref(false)
    const tenantId = computed(() => stateTenantId.value)
    const alertMessage = ref('')
    const alertType = ref('')
    const loading = ref(false)
    const firstTenantLoad = ref(true)
    const tenantToUpdate = ref({})

    const defaultPhone = {
        code: "",
        phone: "",
        tenantId: tenantId.value ,
    }
    const defaultProfile = {
        name: '',
        type: '',
        shortName: '',
        organization: {
            branchOrMain: '',
            numberOfMembers: null,
            structure: '',
            tenantId: tenantId.value ,
            logo: {},
            phone: { ...defaultPhone },
            addresses: [{}],
            website: '',
            fte: '',
            accreditationExpiryDate: '',
        },
        subscribeToNewsletter: false,
        acceptedTermsAndConditions: false,
        surveyToolsFeatureAvailable: false,
    }

    const profileData = ref({ ...defaultProfile })

    const {
        onError: onTenantCreateError,
        mutate: insertTenant,
        onDone: onTenantCreated
    } = useMutation(INSERT_TENANT,
        () => {
            return {
                variables: {
                    tenant: tenantToUpdate.value
                }
            }
        }
    )
    const {
        onError: onUserCreateError,
        mutate: insertUser,
        onDone: onUserCreated
    } = useMutation(INSERT_USER)
    const {
        onError: onDeleteTenantError,
        mutate: deleteTenant,
        onDone: onTenantDeleted
    } = useMutation(DELETE_TENANT)

    onTenantCreated(async ({ data }) => {
        const tenant = camelcaseKeys(data.tenant, {deep: true})
        tenantId.value = tenant.tenantId

        if (tenant.tenantId === '') {
            showAlert.value = true
            alertMessage.value = 'Error occurred while creating tenant.'
            return
        }

        await insertUser(snakecaseKeys({
            user: {
                tenantId: tenant.tenantId,
                email: profileData.value.email,
                subscribeToNewsletter: profileData.value.subscribeToNewsletter,
                accpetedTermsAndConditions: profileData.value.acceptedTermsAndConditions,
                role: 'tenant_admin'
            }
        }, {deep: true}))
    })

    onTenantDeleted(({ data }) => {
        // need to stop loading here in case user create fails and we succeed at this point
        loading.value = false
    })

    onTenantCreateError(error => {
        showAlert.value = true
        alertMessage.value = 'Error occurred while creating tenant.'

        loading.value = false
    })

    onUserCreateError(async (error) => {
        showAlert.value = true
        alertMessage.value = 'Error occurred while creating user.'

        await deleteTenant({
            tenantId: tenantId.value
        })
    })

    onDeleteTenantError(error => {
        // need to make sure we stop loading here otherwise the user may not ever get the button back
        loading.value = false
    })

    return {
        loading, alertMessage, showAlert, tenantId, profileData,
        onUserCreated, insertTenant,
        firstTenantLoad, tenantToUpdate, defaultProfile,
        alertType
    }
}
