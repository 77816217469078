const defaultAddress = {
    unit: '',
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    postcode: '',
    state: '',
    lat: null,
    lng: null,
    archived: false,
}

const mapAddressData = (addressList) => {
    const mappedObj = {...defaultAddress}
    if (!addressList) return null

    for (let i = 0; i < addressList.length; i++) {
        let key = ''
        switch (addressList[i].types[0]) {
            case 'street_number':
                key = 'streetNumber'
                break
            case 'route':
                key = 'street'
                break
            case 'locality':
                key = 'suburb'
                break
            case 'administrative_area_level_1':
                key = 'state'
                break
            case 'postal_code':
                key = 'postcode'
                break
        }
        mappedObj[key] = addressList[i].short_name
    }
    return mappedObj
}

export {
    mapAddressData,
    defaultAddress
}
