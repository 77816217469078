<template lang="pug">
    .detail.mb-5
        v-form(v-if="extendedDetails.organization" ref="form" v-model="isFormValid")
            .grid.grid-flow-col.grid-cols-3.gap-4
                .col-span-1
                    v-text-field(
                        v-model.lazy="fte"
                        label="FTE"
                        :rules="[numberOnly1Dp]"
                        hide-details="auto"
                        filled
                        dense
                    )
                        template(v-slot:append)
                            v-menu(
                                :close-on-content-click="false"
                                offset-y
                                open-on-hover
                                class="align-middle"
                            )
                                template(v-slot:activator="{ on, attrs }")
                                    div(v-bind="attrs" v-on="on" class="p-1") 
                                        fa-icon(icon="question-circle" class="-mt-2 align-baseline text-grey-2 text-xs h-3")
                                div(class="tool-tip-vmenu")
                                    div
                                        div.text-sm
                                            | 1 FTE is 38 practicing hours and includes all doctors working in the practice
                .col-span-1
                    date-picker(
                        ref="datePicker"
                        v-model="expiryDate"
                        :prefixClass="'xmx'"
                        class="bg-navy-light rounded w-full"
                        @change="dateChanged"
                    )
                        template(v-slot:icon-calendar)
                            fa-icon(icon="calendar-alt" class="text-grey-2")
                        template(v-slot:input)
                            v-text-field(
                                class="w-full"
                                placeholder="Select Accreditation Expiry"
                                v-model="formattedDate"
                                filled
                                hide-details
                                dense
                            )
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { format, parse, isValid } from 'date-fns'
import DatePicker from 'vue2-datepicker'
import { numberOnly1Dp } from '@/modules/formRules'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

export default {
    name: 'ExtendedDetails',
    components: { DatePicker },
    setup() {
        const fte = ref('')
        const form = ref({})
        const isFormValid = ref(true)
        const expiryDate = ref('')
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantForm, organization } = storeToRefs(tenantDetailsStore)
        const { updateTenant } = tenantDetailsStore
        const extendedDetails = computed({
            get: () => {
                const dateValue = organization.value?.accreditationExpiryDate
                if (dateValue) {
                    const parsedDate = parse(dateValue, 'yyyy-MM-dd', new Date())
                    if (isValid(parsedDate)) expiryDate.value = parsedDate
                } else {
                    expiryDate.value = ''
                }
                return tenantForm.value || {...defaultProfile}
            },
            set: (profileDetails) => updateTenant({ ...profileDetails })
        })

        const formattedDate = computed(() => {
            if (!expiryDate.value) return ''
            const displayDate = format(expiryDate.value, 'dd/MM/yyyy')
            
            return displayDate || ''
        })

        const validate = () => {
            isFormValid.value = form.value.validate()
            return isFormValid.value
        }

        const dateChanged = () => {
            if (!expiryDate.value) return
            const dateToCheck = expiryDate.value
            const tempDetails = extendedDetails.value
            tempDetails.organization.accreditationExpiryDate = format(dateToCheck, 'yyyy-MM-dd')
            extendedDetails.value = { ...tempDetails }
        }

        watch(() => extendedDetails.value?.organization?.fte, () => {
            fte.value = extendedDetails.value?.organization?.fte
        }, { immediate: true })
        watch(fte, () => {
            if (!extendedDetails.value.organization) extendedDetails.value.organization = {}
            extendedDetails.value.organization.fte = fte.value
        })

        return {
            fte,
            extendedDetails,
            form,
            expiryDate,
            formattedDate,
            numberOnly1Dp,
            isFormValid,
            validate,
            dateChanged
        }
    }
}
</script>