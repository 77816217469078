<template lang="pug">
    //v-form.detail( v-model="isValid" ref="detailRef")
    v-form.detail(v-model="isValid" ref="detailRef")
        .grid.grid-flow-col.grid-cols-3.gap-4
            .col-span-1
                v-text-field(
                    v-model.lazy="organizationName"
                    label="Practice name"
                    :rules="[required]"
                    hide-details="auto"
                    filled dense
                )
            .col-span-1
                v-text-field(
                    v-model.lazy="tenantShortName"
                    label="Short name"
                    disabled
                    hide-details="auto"
                    filled dense
                )
            .col-span-1
                v-select(
                    v-model.lazy="practiceType"
                    :items="practiceTypeItems"
                    label="Practice type"
                    :rules="[required]"
                    filled dense
                )
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { required, shortNameLength, lowercaseDashAndNumber} from '@/modules/formRules'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'Details',
    props: {
        value: Object,
    },
    emits: ['update-name'],
    setup(props, { emit }) {
        const isValid = ref(true)
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantForm, organization } = storeToRefs(tenantDetailsStore)
        const { updateTenantShortName, updateTenantName, updatePracticeType } = tenantDetailsStore
        
        const shortNameUpdated = ref(false)
        const detailRef = ref({})
        const { staticChoices } = useState(['staticChoices'])

        // Computed properties
        const organizationName = computed({
            get() {
                return tenantForm.value?.name
            },
            set(name) {
                // emit('update-name', name)
                updateTenantName(name)
            },
        })
        const tenantShortName = computed({
            get() {
                return tenantForm.value?.shortName
            },
            set(name) {
                shortNameUpdated.value = true
                // if the usr resets it, we can change it again
                if (name === '') shortNameUpdated.value = false
                // emit('update-short-name', name)
                updateTenantShortName(name)
            },
        })
        const practiceType = computed({
            get() {
                return organization.value?.type
            },
            set(practiceType) {
                // emit('update-practice-type', practiceType)
                updatePracticeType(practiceType)
            },
        })
        const practiceTypeItems = computed(() => {
            return staticChoices.value['organization.practice.type'] || []
        })

        // watch(() => props?.value?.name, (newTenantName) => {
        //     if (!shortNameUpdated.value) emit('update-short-name', newTenantName?.toLowerCase()?.replace(/ /g, '-')?.slice(0, MAX_SHORTNAME_LENGTH))
        // })
        const validate = () => {
            // Commenting this out for now
            // isValid.value = detailRef.value?.validate()
            return isValid.value
        }

        return {
            isValid,
            detailRef,
            practiceTypeItems,
            organizationName,
            tenantShortName,
            practiceType,
            required,
            shortNameLength,
            lowercaseDashAndNumber,
            validate,
        }
    },
}
</script>
