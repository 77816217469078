import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail mb-5"},[(_vm.extendedDetails.organization)?_c(VForm,{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"grid grid-flow-col grid-cols-3 gap-4"},[_c('div',{staticClass:"col-span-1"},[_c(VTextField,{attrs:{"label":"FTE","rules":[_vm.numberOnly1Dp],"hide-details":"auto","filled":"","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{staticClass:"align-middle",attrs:{"close-on-content-click":false,"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"p-1"},'div',attrs,false),on),[_c('fa-icon',{staticClass:"-mt-2 align-baseline text-grey-2 text-xs h-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,4286609819)},[_c('div',{staticClass:"tool-tip-vmenu"},[_c('div',[_c('div',{staticClass:"text-sm"},[_vm._v("1 FTE is 38 practicing hours and includes all doctors working in the practice")])])])])]},proxy:true}],null,false,2722797997),model:{value:(_vm.fte),callback:function ($$v) {_vm.fte=$$v},expression:"fte"}})],1),_c('div',{staticClass:"col-span-1"},[_c('date-picker',{ref:"datePicker",staticClass:"bg-navy-light rounded w-full",attrs:{"prefixClass":'xmx'},on:{"change":_vm.dateChanged},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('fa-icon',{staticClass:"text-grey-2",attrs:{"icon":"calendar-alt"}})]},proxy:true},{key:"input",fn:function(){return [_c(VTextField,{staticClass:"w-full",attrs:{"placeholder":"Select Accreditation Expiry","filled":"","hide-details":"","dense":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}})]},proxy:true}],null,false,3702220599),model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}})],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }