<template lang="pug">
    .address
        .grid.grid-cols-6.gap-4
            .col-span-6
                .v-text-field.v-input.with-search-icon
                    fa-icon( icon="search" )
                    gmap-autocomplete(
                        class="w-full"
                        label="Enter an address"
                        :options="autocompleteOptions"
                        :select-first-on-enter="true"
                        :readonly="readOnly"
                        @place_changed="getAddressData"
                        @keyup="autoCompleteKeyupHandler($event)"
                    )
            .col-span-1
                v-text-field(
                    v-model="address.unit"
                    label="Unit"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
            .col-span-5
                v-text-field(
                    v-model="address.addressLine1"
                    label="Address line 1"
                    :rules="readOnly ? [] : [required]"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
            .col-span-6
                v-text-field(
                    v-model="address.addressLine2"
                    label="Address line 2"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
            .col-span-2
                v-text-field(
                    v-model="address.suburb"
                    label="Suburb"
                    :rules="readOnly ? [] : [required]"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
            .col-span-2
                v-text-field(
                    v-model="address.postcode"
                    label="Postcode"
                    :rules="readOnly ? [] : [required]"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
            .col-span-2
                v-select(
                    v-model="address.state"
                    :items="states"
                    ref="state"
                    label="State"
                    data-cy="addressState"
                    :rules="readOnly ? [] : [required]"
                    :readonly="readOnly"
                    filled dense hide-details
                    @change="onChangeHandler"
                )
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { gmapApi } from 'gmap-vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapAddressData, defaultAddress } from '@/modules/address'
import { required } from '@/modules/formRules'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'Address',
    props: {
        value: {
            type: Object,
            default: ({ ...defaultAddress })
        },
    },
    setup(props, { emit }) {
        const { staticChoices } = useState(['staticChoices'])
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantForm, organization } = storeToRefs(tenantDetailsStore)
        const autocompleteOptions = ref({
            componentRestrictions: {
                    country: [
                        'au'
                    ]
                }
        })
        const address = ref({})
        const addressFound = ref(true)

        const readOnly = ref(false)
        const rules = ref([])

        const autoCompleteKeyupHandler = (event) => {
            // we check the target value to set this so we know when to get the geocode
            addressFound.value = !!event.target.value
        }
        const getAddressData = (addressData, placeResultData, id) => {
            // this.$refs.form.reset()
            let addressObject = mapAddressData(addressData.address_components)
            let computedAddress = {...props.value}
            if (addressData) {
                if (addressObject.addressLine1) computedAddress.addressLine1 = addressObject.addressLine1
                if (addressObject.addressLine2) computedAddress.addressLine2 = addressObject.addressLine2

                if (addressObject.streetNumber != null && addressObject.street != null) {
                    computedAddress.addressLine1 = addressObject.streetNumber + ' ' + addressObject.street
                }
                computedAddress.suburb = addressObject.suburb
                computedAddress.state = addressObject.state
                computedAddress.postcode = addressObject.postcode
            }
            address.value = computedAddress
            onChangeHandler()
        }

        const google = computed(() => gmapApi)

        const states = computed(() => {
            return staticChoices.value['address.state'] || []
        })

        const onChangeHandler = () => {
            emit('input', { ...address.value})
        }

        watch(() => props.value, () => {
            address.value = { ...props.value }
        }, { immediate: true })

        return {
            autocompleteOptions,
            addressFound,
            address,
            google,
            staticChoices,
            states,
            readOnly,
            rules,
            onChangeHandler,
            autoCompleteKeyupHandler,
            getAddressData,
            required
        }
    }
}
</script>
