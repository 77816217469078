<template lang="pug">
    div
        PracticeDetailsForm(:tenant-id="tenantId")
</template>

<script>
import PracticeDetailsForm from '@/components/PracticeDetailsForm.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'EditOrganisationView',
    components: { PracticeDetailsForm },
    setup() {
        const { tenantId } = useGetters(['tenantId'])

        return { tenantId }
    }
}
</script>
